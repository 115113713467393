import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const WaveSvg: React.FC = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Box sx={{ width: '100%', overflow: 'hidden', lineHeight: 0, position: 'absolute', bottom: 0, zIndex: -1 }}>
      <svg
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        style={{ display: 'block', width: '100%', height: '150px' }}
      >
        <path
          fill={isDarkMode ? '#333333' : 'lightgreen'}
          d="M0,256L30,234.7C60,213,120,171,180,176C240,181,300,235,360,256C420,277,480,267,540,240C600,213,660,171,720,138.7C780,107,840,85,900,101.3C960,117,1020,171,1080,176C1140,181,1200,139,1260,138.7C1320,139,1380,181,1410,202.7L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        />
        <path
          fill={isDarkMode ? '#222222' : '#EEEEEE'}
          d="M0,288L30,266.7C60,245,120,203,180,202.7C240,203,300,245,360,256C420,267,480,245,540,208C600,171,660,117,720,85.3C780,53,840,43,900,69.3C960,96,1020,160,1080,186.7C1140,213,1200,203,1260,181.3C1320,160,1380,128,1410,112L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"
        />
      </svg>
    </Box>
  );
};

export default WaveSvg;