import { Typography, Box, Link, useMediaQuery, useTheme } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/text-animation.css';
import { supportEmail } from '../../common';
import { useTranslation } from 'react-i18next';

export const AndroidBetaTest = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const darkMode = theme.palette.mode === 'dark';
	
	return <Box sx={{ position: { md: 'sticky' }, top: { md: 300 }, alignSelf: 'flex-start', width: { xs: '100%', md: '200px' }, ml: { md: 4 }, mb: { xs: 4, md: 0 }, mt: { md: 0 }, mr: { md: 4 }, zIndex: 1 }}>
          <Box sx={{ p: 2, borderRadius: 2, backgroundColor: 'rgba(144, 238, 144, 0.5)', mt: { md: 4 }, textAlign: 'center' }}>
            <Typography variant="body1" paragraph>
              {t('lookingForBetaTesters')} <Link href={`mailto:${supportEmail}`} sx={{color: 'inherit', fontWeight: '500'}}>{supportEmail}</Link>.
              <br />
              {t('premiumForAllParticipants')}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', height: 'auto', margin: '15px 0', backgroundColor: 'white', borderRadius: '15px' }}>
                  <img style={{ width: 100, height: 57, margin: '15px 0' }} loading='lazy' src="https://developer.android.com/static/images/brand/Android_Robot_200.png" alt="Android Logo" />
                </Box>
                <Box sx={{ fontSize: 8, color: darkMode ? 'lightgray' : "gray", textAlign: 'center' }}>
                  The Android robot is reproduced or modified from work created and shared by Google and used according to terms described in the 
                  <Link href="https://creativecommons.org/licenses/by/3.0/" target="_blank" sx={{fontSize: 8}}> Creative Commons 3.0 Attribution License</Link>.
                </Box>
              </Box>
            </Typography>
          </Box>
        </Box>
};