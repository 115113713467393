import React, { useState, useEffect, Suspense, lazy } from 'react';
import { CssBaseline, createTheme, ThemeProvider, useMediaQuery, Box, CircularProgress } from '@mui/material';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import OpenAppPage from './components/coupons/OpenAppPage';

// Lazy load components
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'));
const AboutPage = lazy(() => import('./components/coupons/AboutPage'));
const AccountDeletionPage = lazy(() => import('./components/coupons/AccountDeletionPage'));
const PrivacyPolicyPage = lazy(() => import('./components/coupons/PrivacyPolicyPage'));
const TermsOfUsePage = lazy(() => import('./components/coupons/TermsOfUsePage'));

const App: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#90caf9' : '#1976d2',
      },
      background: {
        default: darkMode ? '#121212' : '#ffffff',
        paper: darkMode ? '#121212' : '#ffffff',
      },
      text: {
        primary: darkMode ? '#ffffff' : '#000000',
        secondary: darkMode ? '#b0bec5' : '#757575',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh' }}>
        <Router>
          <Header onThemeChange={handleThemeChange} darkMode={darkMode} />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Suspense fallback={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><CircularProgress /></Box>}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/coupons/about" element={<AboutPage />} />
                <Route path="/coupons/terms-of-use" element={<TermsOfUsePage />} />
                <Route path="/coupons/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/coupons/account-deletion" element={<AccountDeletionPage />} />
                <Route path="/coupons/open/*" element={<OpenAppPage />} />
              </Routes>
            </Suspense>
          </Box>
          <Footer />
        </Router>
      </Box>
    </ThemeProvider>
  );
};

export default App;