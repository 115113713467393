import React from 'react';
import { Container, Typography, Box, Link, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AndroidBetaTest } from './AndrodBetaTest';

const OpenAppPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const darkMode = theme.palette.mode === 'dark';

  return (
	<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, position: 'relative', gap: { xs: 0, md: 4 }}}>
    <Container sx={{ pb: 10, display: 'flex', justifyContent: 'center', alignItems: 'flex-start', flexDirection: { xs: 'column', md: 'row' } }}> {/* Add padding to the bottom to prevent overlap with footer */}
      
      {/* iOS Panel */}
      <Box sx={{ flexGrow: 1, maxWidth: { xs: '100%', md: '60%' }, textAlign: 'center' }}>
        
        {/* Rounded rectangle with text */}
        <Box sx={{ my: 4, p: 2, borderRadius: 2, backgroundColor: 'rgba(255, 182, 193, 0.5)', textAlign: 'center' }}>
          <Typography sx={{ fontSize: '1.2rem' }}>
            {t('downloadCouponsApp')}
          </Typography>
        </Box>
        
        {/* iOS app promotion panel */}
        <Box sx={{ textAlign: 'center', my: 2, p: 2, borderRadius: 2, backgroundColor: 'rgba(93, 116, 130, 0.3)' }}>
          <Typography gutterBottom sx={{ mt: 2, textAlign: 'center', fontSize: '1.2rem' }}>
            {t('availableForIOSAndMacOS')}
          </Typography>
          <img src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/769b24910a7a6dd66120d3629a5cfc33/ef5093cc05a31da9b7f29afeeb4c6120.png" alt={t('qrCodeAlt')} style={{ width: '150px', height: '150px', marginBottom: '20px', borderRadius: '5px' }} />
          <a href="https://apps.apple.com/us/app/coupons-by-lokidev/id6553964089?itsct=apps_box_badge&amp;itscg=30200" style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '5px', width: '100%', height: 'auto' }}>
            <img loading='lazy' src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1722643200" alt="Download on the App Store" style={{ borderRadius: '5px', width: isMobile ? '270px' : '120px', height: isMobile ? '90px' : '40px' }} />
          </a>
        </Box>
      </Box>

      {/* Android Beta Test Panel */}
      <Box sx={{ width: { xs: '100%', md: '30%' }, ml: { md: 4 }, mt: { xs: 4, md: 0 } }}>
        <AndroidBetaTest />
      </Box>
    </Container>
	</Box>
  );
};

export default OpenAppPage;