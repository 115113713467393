import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const languages = [
  { code: 'en', name: 'English', label: 'English (English)', flag: 'gb' },
  { code: 'de', name: 'Deutsch', label: 'Deutsch (German)', flag: 'de' },
  { code: 'es', name: 'Español', label: 'Español (Spanish)', flag: 'es' },
  { code: 'fr', name: 'Français', label: 'Français (French)', flag: 'fr' },
  { code: 'it', name: 'Italiano', label: 'Italiano (Italian)', flag: 'it' },
  { code: 'ja', name: '日本語', label: '日本語 (Japanese)', flag: 'jp' },
  { code: 'ko', name: '한국어', label: '한국어 (Korean)', flag: 'kr' },
  { code: 'pt', name: 'Português', label: 'Português (Portuguese)', flag: 'pt' },
  { code: 'ru', name: 'Русский', label: 'Русский (Russian)', flag: 'ru' },
  { code: 'tr', name: 'Türkçe', label: 'Türkçe (Turkish)', flag: 'tr' },
];

const LanguageButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginRight: theme.spacing(6),
}));

const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [langAnchorEl, setLangAnchorEl] = useState<null | HTMLElement>(null);

  const handleLangMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangMenuClose = () => {
    setLangAnchorEl(null);
  };

  const handleLangChange = (lang: string) => {
    i18n.changeLanguage(lang);
    handleLangMenuClose();
  };

  const currentLang = languages.find((lang) => lang.code === i18n.language);

  return (
    <>
      <LanguageButton
        color="inherit"
        aria-label='Language'
        startIcon={<LanguageIcon />}
        onClick={handleLangMenuOpen}
      >
        {currentLang?.name}
      </LanguageButton>
      <Menu
        anchorEl={langAnchorEl}
        open={Boolean(langAnchorEl)}
        onClose={handleLangMenuClose}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleLangChange(lang.code)}>
            <span className={`flag-icon flag-icon-${lang.flag}`} style={{ marginRight: 8 }}></span>
            {lang.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;